import './index.scss';

import _ from 'lodash';
import React, { useContext, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import styled, { keyframes } from 'styled-components';
import useSWR from 'swr';

import { fetcherV3 } from '../../api/fetcher';
import { DEFAULT_LOGO, PROJECT_ID_KEY } from '../../constants/smrsFrontstage';
import ProjectContext from '../../context/Project/ProjectContext';
import { Flex, Padding } from '../../Mixin';
import { POINT_HISTORY, SETTING_PROFILE } from '../../routes/routePaths';
import { formatUserInfo } from '../../utils/dataFormaters/user';

const StyledWrapper = styled.div`
  ${Padding.standard}
  padding-top: 1.625rem;
  padding-bottom: 0.5rem;
  position: sticky;
  top: 0;
  width: 100%;
  z-index: 10;
  box-shadow: 0 0 2rem ${({ theme }) => theme.white};
  margin: 0 auto;
`;

const StyledHeader = styled.div`
  ${Flex}
  align-items: center;
  max-width: 22.5rem;
  margin: 0 auto 1.75rem;
`;

const StyledName = styled.p`
  color: ${({ theme }) => theme.mainColor};
  font-size: 1.125rem;
  font-weight: 500;
`;

const StyledPortrait = styled.div`
  height: 5rem;
  width: 5rem;
  border-radius: 50%;
  overflow: hidden;

  img {
    width: 100%;
    object-fit: cover;
  }
`;

const StyledContainer = styled.div`
  padding: 0 1rem;
  position: relative;
  z-index: 1;
  max-width: 22.5rem;
  margin: 0 auto;
`;

const StyledInfo = styled.div`
  ${Flex}
  align-items: center;
`;

const StyledContent = styled.div`
  flex: 1;
  margin-left: 1.125rem;
`;

const StyledList = styled.div`
  ${Flex}
  margin-bottom: 0.75rem;

  div {
    text-align: center;
  }

  p {
    color: ${({ theme }) => theme.mainColor};
  }

  small {
    color: ${({ theme }) => theme.gray.medium};
    font-size: 0.75rem;
  }
`;

const Progress = keyframes`
  0% {
    transform: translateX(-100%);
  }

  100% {
    transform: translateX(0);
  }
`;

const StyledProgress = styled.div`
  border: 1px solid ${({ theme }) => theme.mainColor};
  border-radius: 0.3rem;
  height: 0.625rem;
  position: relative;
  overflow: hidden;

  &::before {
    content: '';
    background-color: ${({ theme }) => theme.mainColor};
    height: 100%;
    width: 100%;
    opacity: 0.1;
    position: absolute;
    left: 0;
    top: 0;
  }

  &::after {
    animation: ${Progress} 0.5s ease-in-out 1 normal;
    content: '';
    background-color: ${({ theme }) => theme.mainColor};
    border-radius: 0.3rem;
    height: 100%;
    width: ${({ progress }) => (1 - progress) * 100}%;
    position: absolute;
    left: 0;
    top: 0;
  }
`;

const StyledDescription = styled.p`
  color: ${({ theme }) => theme.mainColor};
  opacity: 0.5;
  margin-top: 0.3125rem;
  font-size: 0.875rem;
`;

const StyledLink = styled(Link)`
  color: ${({ theme }) => theme.gray.medium};
  border: 1px solid ${({ theme }) => theme.gray.light};
  border-radius: 0.25rem;
  padding: 0.3125rem 0;
  font-size: 0.875rem;
  text-decoration: none;
  text-align: center;
  width: 49%;

  .rotate-45 {
    transform: rotate(-45deg);
  }

  i {
    margin-left: 0.25rem;
    font-size: 0.85rem;
  }
`;

const StyledButtonWrapper = styled.div`
  ${Flex}
  position: relative;
  z-index: 1;
  max-width: 22.5rem;
  margin: 1.875rem auto 0;
`;

const defaultLevel = {
  title: '',
  score: 0,
};

const Dashboard = () => {
  const projectId = localStorage.getItem(PROJECT_ID_KEY);
  const { project } = useContext(ProjectContext);
  const { t } = useTranslation();
  const { data: userData } = useSWR(`/project/${projectId}/user`, (req) =>
    fetcherV3(req, formatUserInfo),
  );
  const levels = useMemo(() => {
    if (project && userData) {
      const { levelJson } = project;
      const { levelIndex, totalAwardPoint } = userData;

      if (levelIndex < levelJson.length - 1) {
        return {
          current: levelJson[levelIndex],
          next: levelJson[levelIndex + 1],
          remains: levelJson[levelIndex + 1].score - totalAwardPoint,
        };
      }
      return {
        current: levelJson[levelIndex],
        next: levelJson[levelIndex],
        remains: 0,
      };
    }
    return {
      current: defaultLevel,
      next: defaultLevel,
      remains: 0,
    };
  }, [project, userData]);

  return (
    userData && (
      <StyledWrapper>
        <div className="dashboard-backdrop" />
        <StyledContainer>
          <StyledHeader>
            <StyledName>{userData.name}</StyledName>
            <img
              src={_.get(project, 'memberCardImageUrl', DEFAULT_LOGO)}
              alt="logo"
            />
          </StyledHeader>
          <StyledInfo>
            <StyledPortrait>
              <img src={userData.profilePic} alt={userData.name} />
            </StyledPortrait>
            <StyledContent>
              <StyledList>
                <div>
                  <p>{userData.level}</p>
                  <small>Level</small>
                </div>
                <div>
                  <p>{userData.levelName}</p>
                  <small>Title</small>
                </div>
                <div>
                  <p>{userData.validAwardPoint}</p>
                  <small>Point</small>
                </div>
              </StyledList>
              <StyledProgress
                progress={
                  levels.remains >= 0
                    ? levels.remains /
                      (levels.next.score - levels.current.score)
                    : 100
                }
              />
              <StyledDescription>
                {levels.remains >= 0 &&
                  t('member.pointsToNextLevel', {
                    point: levels.remains,
                    level: levels.next.title,
                  })}
              </StyledDescription>
            </StyledContent>
          </StyledInfo>
        </StyledContainer>

        <StyledButtonWrapper>
          <StyledLink to={SETTING_PROFILE}>
            {t('member.personalProfile')}
            <i className="gsif gsif-edit" />
          </StyledLink>
          <StyledLink to={POINT_HISTORY}>
            {t('history.history')}
            <i className="gsif gsif-arrow-right rotate-45" />
          </StyledLink>
        </StyledButtonWrapper>
      </StyledWrapper>
    )
  );
};

export default Dashboard;
