import React from 'react';
import './index.scss';
import _ from 'lodash';
import PropTypes from 'prop-types';

/**
 * @param {Object} props
 * @param {string} size - string
 * @param {string} color - string
 * @param {Object} $style - object
 * @returns
 */
function Loading({ size = '2.5rem', color = '#6d757d', $style }) {
  const loadingCircleStyle = {
    ...$style,
    width: size,
    height: size,
  };

  const dotStyle = {
    backgroundColor: color,
  };

  return (
    <div className="loading-circle" style={loadingCircleStyle}>
      {_.map(Array(12), (item, index) => {
        return (
          <div
            key={index}
            className={`loading-circle${index + 1} loading-child`}
          >
            <div className="loading-dot" style={dotStyle}></div>
          </div>
        );
      })}
    </div>
  );
}

Loading.propTypes = {
  size: PropTypes.string,
  color: PropTypes.string,
  $style: PropTypes.object,
};

export default Loading;
