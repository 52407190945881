import './index.scss';

import _ from 'lodash';
import React, { useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import useSWR from 'swr';

import { fetcherV3 } from '../../api/fetcher';
import { DEFAULT_LOGO, PROJECT_ID_KEY } from '../../constants/smrsFrontstage';
import ProjectContext from '../../context/Project/ProjectContext';
import { FontFamily, Padding } from '../../Mixin';
import { POINT_HISTORY, SETTING_PROFILE } from '../../routes/routePaths';
import { formatUserInfo } from '../../utils/dataFormaters/user';
import LoadingFullScreen from '../LoadingFullScreen';
import { CardContent } from './CardContent';
import { MemberQRCode } from './MemberQRCode';
import { Portrait } from './Portrait';

const StyledUl = styled.ul`
  list-style: none;
  display: flex;
  padding: 0;
  margin: 0;

  li {
    align-items: center;
    display: flex;
    opacity: 0.75;

    &::after {
      content: '';
      height: 0.65rem;
      display: block;
      border-right: 1px solid ${({ theme }) => theme.color};
      margin: 0 0.6rem;
    }

    &:last-child {
      &::after {
        content: none;
      }
    }
  }
`;

const StyledLink = styled(Link)`
  align-items: center;
  color: ${({ theme }) => theme.color};
  display: flex;
  font-size: 0.875rem;
  text-decoration: none;

  i {
    font-size: 0.625rem;
    margin: 0 0 0 5px;
  }

  .rotate-45 {
    transform: rotate(-45deg);
  }
`;

const StyledButton = styled.button`
  background-color: ${({ theme }) => theme.transparent};
  border: 0;
  color: ${({ theme }) => theme.color};
  padding: 0;

  i {
    &.gsif {
      font-size: 1.5rem;
    }
  }
`;

const StyledNumber = styled.p`
  ${FontFamily.mono}
`;

const StyledWrapper = styled.div`
  ${Padding.standard}
  padding-top: 1.625rem;
  padding-bottom: 0.5rem;
  position: sticky;
  top: 0;
  width: 100%;
  z-index: 2;
`;

const StyledLogo = styled.img`
  max-width: 6.25rem;
`;

const MemberCard = () => {
  const projectId = localStorage.getItem(PROJECT_ID_KEY);
  const { t } = useTranslation();
  const { project } = useContext(ProjectContext);
  const [isOpen, setIsOpen] = useState(false);
  const { data: userData } = useSWR(`/project/${projectId}/user`, (req) =>
    fetcherV3(req, formatUserInfo),
  );

  if (!userData) {
    return <LoadingFullScreen />;
  }

  return (
    <>
      <StyledWrapper>
        <div className="member-card-backdrop" />
        <div className="member-card theme-card">
          <div className="card-header">
            <StyledNumber>{userData.memberCardNum}</StyledNumber>
            <StyledLogo
              src={_.get(project, 'memberCardImageUrl', DEFAULT_LOGO)}
              alt={''}
            />
          </div>
          <div className="card-content">
            <Portrait img={userData.profilePic} name={userData.name} />
            <CardContent user={userData} />
          </div>
          <div className="card-footer">
            <StyledUl>
              <li>
                <StyledLink to={SETTING_PROFILE}>
                  {t('member.personalProfile')}
                  <i className="gsif gsif-edit" />
                </StyledLink>
              </li>
              <li>
                <StyledLink to={POINT_HISTORY}>
                  {t('history.history')}
                  <i className="gsif gsif-arrow-right rotate-45" />
                </StyledLink>
              </li>
            </StyledUl>
            <StyledButton onClick={() => setIsOpen(true)}>
              <i className="gsif gsif-qrcode" />
            </StyledButton>
          </div>
        </div>
      </StyledWrapper>
      {isOpen && (
        <MemberQRCode
          isOpen={isOpen}
          onCancel={() => setIsOpen(false)}
          title={t('field.offline.pointToPriceRate', {
            dollar: _.get(project, 'pointToPriceRate', 0),
          })}
          info={project.priceToPointRuleDesc}
        />
      )}
    </>
  );
};

export default MemberCard;
