import PropTypes from 'prop-types';
import React from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

import { FontFamily } from '../../Mixin';

const StyledWrapper = styled.div`
  display: flex;
  justify-content: start;
  margin-left: 1.18rem;
  flex: 1;

  & > div {
    &:first-of-type {
      padding-right: 1rem;
    }
  }
`;

const StyledLabel = styled.p`
  ${FontFamily.mono}
  color: ${({ theme }) => theme.color};
  font-size: 0.75rem;
  font-weight: 400;
  opacity: 0.75;
`;

const StyledDiv = styled.div`
  margin-bottom: 0.812rem;
`;

/**
 * @typedef {Object} User
 * @property {string} name
 * @property {number} points
 * @property {number} level
 */

// TODO: consider remove comments afterward
/**
 * @param {Object} props
 * @param {User} user - `User`
 * @returns JSX.Element
 */
export const CardContent = ({ user }) => {
  const { t } = useTranslation();

  return (
    <StyledWrapper>
      <div>
        <StyledDiv>
          <StyledLabel>{t('member.cardHolder')}</StyledLabel>
          <p>{user.name}</p>
        </StyledDiv>
        <div>
          <StyledLabel>{t('member.totalPoints')}</StyledLabel>
          <p>{user.validAwardPoint}</p>
        </div>
      </div>
      <div>
        <StyledDiv>
          <StyledLabel>{t('member.level')}</StyledLabel>
          <p>{user.level}</p>
        </StyledDiv>
        <div>
          <StyledLabel>{t('member.title')}</StyledLabel>
          <p>{user.levelName}</p>
        </div>
      </div>
    </StyledWrapper>
  );
};

CardContent.prototype = {
  user: PropTypes.object,
};
