import React from 'react';
import { Redirect } from 'react-router-dom';
import useSWR from 'swr';

import { fetcherV3 } from '../api/fetcher';
import { LOYAL_T_KEY, PROJECT_ID_KEY } from '../constants/smrsFrontstage';
import { formatUserInfo } from '../utils/dataFormaters/user';
import { LANDING, LOGIN, SIGN_UP } from './routePaths';

const PrivateRoute = ({
  needAuth = false,
  needSignupCompleted = false,
  children,
}) => {
  const projectId = localStorage.getItem(PROJECT_ID_KEY);
  const token = localStorage.getItem(LOYAL_T_KEY);
  const {
    data: userData,
    error: userError,
  } = useSWR(`/project/${projectId}/user`, (req) =>
    fetcherV3(req, formatUserInfo),
  );

  if (userError) {
    return <Redirect to={LANDING} />;
  }

  if (needAuth && !token) {
    return <Redirect to={LOGIN} />;
  }

  if (userData && needSignupCompleted) {
    if (!userData.signupCompletedAt) {
      return <Redirect to={SIGN_UP} />;
    }
  }

  return children;
};

export default PrivateRoute;
