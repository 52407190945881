import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { NavLink } from 'react-router-dom';
import styled from 'styled-components';

import ProjectContext from '../../context/Project/ProjectContext';
import { Flex } from '../../Mixin';
import { NAVBAR_MENU } from '../../routes/nav';

const StyledNav = styled.nav`
  background-color: ${({ theme }) => theme.white};
  bottom: 0;
  border-top: 1px solid ${({ theme }) => theme.gray.lighter};
  left: 0;
  position: fixed;
  width: 100%;
  z-index: 99;
`;

const StyledUl = styled.ul`
  display: flex;
  flex: 1;
  height: 4.25rem;
  list-style: none;
`;

const StyledLi = styled.li`
  ${Flex.center}
  flex: 1;

  a {
    align-items: center;
    color: ${({ theme }) => theme.gray.medium};
    display: flex;
    flex-direction: column;
    font-size: 0.875rem;
    text-decoration: none;

    i {
      font-size: 1.75rem;
      margin-bottom: 0.25rem;
    }

    .active-icon {
      display: none;
    }

    &.active {
      color: ${({ theme }) => theme.mainColor};

      .inactive-icon {
        display: none;
      }

      .active-icon {
        display: block;
      }
    }
  }

  img {
    height: 1.875rem;
    width: 1.875rem;
    margin-bottom: 3px;
  }
`;

export const Navbar = () => {
  const { project } = useContext(ProjectContext);
  const { t } = useTranslation();

  return (
    <>
      <StyledNav>
        {project && (
          <StyledUl>
            {NAVBAR_MENU(project.workingMode).map((item) => (
              <StyledLi key={item.name}>
                <NavLink to={item.to} activeClassName="active">
                  <span className="active-icon">
                    <i className={`gsif gsif-${item.active}`} />
                  </span>
                  <span className="inactive-icon">
                    <i className={`gsif gsif-${item.inactive}`} />
                  </span>
                  {t(`navigation.${item.name}`)}
                </NavLink>
              </StyledLi>
            ))}
          </StyledUl>
        )}
      </StyledNav>
    </>
  );
};
