import React, { useContext } from 'react';
import Dashboard from '../Dashboard';
import MemberCard from '../MemberCard';
import { Navbar } from '../Navbar';
import ProjectContext from '../../context/Project/ProjectContext';
import {
  ONLIN_AND_OFFLINE_FLOW,
  ONLINE_FLOW,
} from '../../constants/smrsFrontstage';

export const LayoutEmpty = ({ children }) => children;

export const LayoutWithCard = ({ children }) => {
  const { project } = useContext(ProjectContext);

  return (
    <>
      {project.workingMode === ONLIN_AND_OFFLINE_FLOW && <MemberCard />}
      {project.workingMode === ONLINE_FLOW && <Dashboard />}
      {children}
      <Navbar />
    </>
  );
};

export const LayoutWithoutCard = ({ children }) => (
  <>
    {children}
    <Navbar />
  </>
);
