import { hexToRgb, textColor } from '../utils/color';
import { DEFAULT_THEME_COLOR } from './smrsFrontstage';

export const initSignUpFormState = {
  name: '',
  birthday: '',
  tel: '',
  agreeTerms: false,
};

export const initPreference = {
  fillBirthday: 0,
  fillName: 0,
  fillTel: 0,
  needOtp: 0,
};

const rgb = hexToRgb(DEFAULT_THEME_COLOR);
export const defaultTheme = {
  primaryColor: '#a72126',
  mainColor: DEFAULT_THEME_COLOR,
  rgb,
  color: textColor(rgb.r, rgb.g, rgb.b),
  gray: {
    lightest: 'rgba(216, 216, 216, 0.18)',
    lighter: 'rgba(151, 151, 151, 0.32)',
    light: '#c3c3c3',
    medium: '#979797',
    dark: '#383838',
  },
  white: '#fff',
  black: '#000',
  transparent: 'rgba(255, 255, 255, 0)',
  mask: 'rgba(0, 0, 0, 0.55)',
  warn: '#ba0510',
};
