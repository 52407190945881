import React, { useState } from 'react';
import ProjectContext from './ProjectContext';
import initialState from './initialState';

const ProjectProvider = ({ children }) => {
  const [project, setProject] = useState(initialState);

  return (
    <ProjectContext.Provider value={{ project, setProject }}>
      {children}
    </ProjectContext.Provider>
  );
};

export default ProjectProvider;
