import { css } from 'styled-components';

const normalFont = css`
  font-family: 'PingFang TC', 'Microsoft JhengHei', 'Microsoft YaHei',
    'sans-serif';
`;
const monoFont = css`
  font-family: 'Courier New', Courier, monospace;
`;

export const FontFamily = {
  normal: normalFont,
  mono: monoFont,
};

const flexCenter = css`
  align-items: center;
  display: flex;
  justify-content: center;
`;

const flexBetween = css`
  align-items: center;
  display: flex;
  justify-content: space-between;
`;

export const Flex = {
  center: flexCenter,
  between: flexBetween,
};

const standardPadding = css`
  padding-left: 1.6875rem;
  padding-right: 1.6875rem;
`;

export const Padding = {
  standard: standardPadding,
};

export const Scrollbar = css`
  overflow: auto;
  overflow: overlay;
  scrollbar-color: rgba(black, 0.3) transparent;
  &::-webkit-scrollbar {
    width: 8px;
  }
  &::-webkit-scrollbar-track {
    background-color: rgba(black, 0);
  }
  &::-webkit-scrollbar-thumb {
    background: rgba(black, 0.3);
    background-clip: padding-box;
    border-radius: 4px;
    border: solid 2px rgba(0, 0, 0, 0);
  }
  &::-webkit-scrollbar-thumb:hover {
    background: rgba(black, 0.7);
    background-clip: padding-box;
    border-radius: 4px;
    border: solid 2px rgba(0, 0, 0, 0);
  }
`;
