import { lazy } from 'react';
import {
  LayoutWithCard,
  LayoutWithoutCard,
  LayoutEmpty,
} from '../components/Layout';

import * as paths from './routePaths';

const Login = lazy(() => import('../pages/Login'));
const Landing = lazy(() => import('../pages/Landing'));
const SignUp = lazy(() => import('../pages/SignUp'));
const OtpVerify = lazy(() => import('../pages/OtpVerify'));
const MemberInfo = lazy(() => import('../pages/MemberInfo'));
const MemberCoupon = lazy(() => import('../pages/MemberCoupon'));
const SettingProfile = lazy(() => import('../pages/SettingProfile'));
const PageNotFound = lazy(() => import('../pages/PageNotFound'));
const DefaultPage = lazy(() => import('../pages/DefaultPage'));
const FieldPage = lazy(() => import('../pages/FieldPage'));
const HomePage = lazy(() => import('../pages/HomePage'));
const PrizePage = lazy(() => import('../pages/PrizePage'));
const CouponPage = lazy(() => import('../pages/CouponPage'));
const UsedCouponPage = lazy(() => import('../pages/UsedCouponPage'));
const PointHistory = lazy(() => import('../pages/HistoryPage/PointHistory'));
const TransactionHistory = lazy(() =>
  import('../pages/HistoryPage/TransactionHistory'),
);

const routes = [
  {
    exact: true,
    path: paths.LOGIN,
    component: Login,
    layout: LayoutEmpty,
  },
  {
    path: paths.LANDING_TOKEN,
    component: Landing,
    layout: LayoutEmpty,
  },
  {
    path: paths.LANDING,
    component: Landing,
    layout: LayoutEmpty,
  },
  {
    exact: true,
    path: paths.SIGN_UP,
    component: SignUp,
    needAuth: true,
    layout: LayoutEmpty,
  },
  {
    exact: true,
    path: paths.OTP_VERIFY,
    component: OtpVerify,
    needAuth: true,
    layout: LayoutEmpty,
  },
  {
    path: paths.MEMBER_INFO,
    component: MemberInfo,
    needAuth: true,
    needSignupCompleted: true,
    layout: LayoutWithoutCard,
  },
  {
    path: paths.MEMBER_COUPON,
    component: MemberCoupon,
    needAuth: true,
    needSignupCompleted: true,
    layout: LayoutWithCard,
  },
  {
    exact: true,
    path: paths.SETTING_PROFILE,
    component: SettingProfile,
    needAuth: true,
    needSignupCompleted: true,
    layout: LayoutWithoutCard,
  },
  {
    exact: true,
    path: paths.PAGE_NOT_FOUND,
    component: PageNotFound,
    layout: LayoutEmpty,
  },
  {
    exact: true,
    path: paths.FIELD_PAGE,
    needAuth: true,
    component: FieldPage,
    layout: LayoutWithCard,
  },
  {
    exact: true,
    path: paths.HOME_PAGE,
    needAuth: true,
    component: HomePage,
    layout: LayoutWithCard,
  },
  {
    exact: true,
    path: paths.PRIZE_PAGE,
    needAuth: true,
    component: PrizePage,
    layout: LayoutWithCard,
  },
  {
    exact: true,
    path: paths.COUPON_PAGE_USED_OFFLINE,
    needAuth: true,
    component: UsedCouponPage,
    layout: LayoutEmpty,
  },
  {
    exact: true,
    path: paths.COUPON_PAGE_USED_ONLINE,
    needAuth: true,
    component: UsedCouponPage,
    layout: LayoutEmpty,
  },
  {
    exact: true,
    path: paths.COUPON_PAGE,
    needAuth: true,
    component: CouponPage,
    layout: LayoutWithCard,
  },
  {
    exact: true,
    path: paths.POINT_HISTORY,
    needAuth: true,
    component: PointHistory,
    layout: LayoutWithCard,
  },
  {
    exact: true,
    path: paths.TRANSACTION_HISTORY,
    needAuth: true,
    component: TransactionHistory,
    layout: LayoutWithCard,
  },
  {
    path: paths.ROOT,
    redirect: paths.LOGIN,
    component: DefaultPage,
    layout: LayoutEmpty,
  },
];

export default routes;
