import _ from 'lodash';
import PropTypes from 'prop-types';
import QRCode from 'qrcode.react';
import React, { useContext, useEffect, useRef, useState } from 'react';
import styled, { keyframes } from 'styled-components';
import { mutate } from 'swr';

import { getQRCode } from '../../api/qrcode';
import {
  DEFAULT_LOGO,
  PROJECT_ID_KEY,
  USER_INFO_REFRESH_TIME,
} from '../../constants/smrsFrontstage';
import ProjectContext from '../../context/Project/ProjectContext';
import { Flex } from '../../Mixin';
import Modal from '../Modal';

const loading = keyframes`
  0% {
    opacity: 0.3;
  }
  50% {
    opacity: 1;
  }
  100% {
    opacity: 0.3;
  }
`;

const Counter = styled.div`
  color: ${({ theme, over }) => {
    if (over) {
      return theme.warn;
    }
    return theme.gray.medium;
  }};
  margin: 0.75rem 0;
  font-size: 0.875rem;
`;

const StyledDiv = styled.div`
  ${Flex.center}
  flex-direction: column;
`;

const StyledPlaceholder = styled.div`
  height: ${({ size }) => size}px;
  width: ${({ size }) => size}px;
  background-color: ${({ theme }) => theme.gray.lighter};
  display: flex;
  align-items: center;
  justify-content: center;
  animation: 1.5s ${loading} linear infinite;

  img {
    height: ${({ logoSize }) => logoSize.height}px;
    width: ${({ logoSize }) => logoSize.width}px;
  }
`;

export const QRCodeModal = ({
  id = 'qrcode',
  isOpen = false,
  onCancel = () => {},
  reqBody = null,
  children,
}) => {
  const projectId = localStorage.getItem(PROJECT_ID_KEY);
  const { project } = useContext(ProjectContext);
  const [data, setData] = useState(null);
  const [remains, setRemains] = useState(0);
  const [aspect, setAspect] = useState({ w: 0, h: 0 });
  const logoUrl = _.get(project, 'logoImageUrl', DEFAULT_LOGO);
  const inter = useRef();
  const logo = useRef();

  useEffect(() => {
    // initialize qrcode data
    const qrcode = async () => {
      const res = await getQRCode(projectId, reqBody);
      setData(res);
      setRemains(res.qrTokens.expireTime);
    };
    qrcode();

    return () => {
      setTimeout(() => {
        // wait 3 sec and refetch user data to update user points
        mutate(`/project/${projectId}/user`);
      }, USER_INFO_REFRESH_TIME);
    };
  }, []);

  // this effect will keep the clock ticking
  useEffect(() => {
    inter.current = setInterval(() => {
      setRemains((prev) => prev - 1);
    }, 1000);

    return () => {
      clearInterval(inter.current);
    };
  }, [data]);

  useEffect(() => {
    if (remains === 0) {
      // prob going to refresh qrcode?
      clearInterval(inter.current);
    }
  }, [remains]);

  useEffect(() => {
    // adjust logo size to prevent the logo covers too much space.
    const logoSetup = () => {
      const { height, width } = logo.current;
      if (width > height) {
        const ratio = height / width;
        setAspect({
          w: 80,
          h: ratio * 80,
        });
      } else {
        const ratio = width / height;
        setAspect({
          w: ratio * 50,
          h: 50,
        });
      }
    };

    if (logo.current) {
      logo.current.addEventListener('load', logoSetup);
    }
  }, [logo]);

  return (
    <Modal id={id} isOpen={isOpen} onRequestClose={onCancel}>
      <img ref={logo} src={logoUrl} style={{ display: 'none' }} />
      <StyledDiv>
        {data ? (
          <>
            <QRCode
              value={data.qrTokens.token}
              size={window.innerWidth / 1.8}
              level={'H'}
              renderAs={'svg'}
              imageSettings={{
                src: logoUrl,
                height: aspect.h,
                width: aspect.w,
                excavate: true,
              }}
            />
            <Counter over={remains === 0}>
              {`0${Math.floor(remains / 60)}`}:
              {remains % 60 < 10 ? `0${remains % 60}` : remains % 60}
            </Counter>
          </>
        ) : (
          <>
            <StyledPlaceholder
              size={window.innerWidth / 1.8}
              logoSize={{ height: aspect.h, width: aspect.w }}
            >
              <img src={DEFAULT_LOGO} />
            </StyledPlaceholder>
            <Counter over={remains === 0}>--:--</Counter>
          </>
        )}
      </StyledDiv>
      {children}
    </Modal>
  );
};

QRCodeModal.propTypes = {
  id: PropTypes.string,
  isOpen: PropTypes.bool,
  onCancel: PropTypes.func,
  reqBody: PropTypes.shape({
    targetClass: PropTypes.oneOf(['Prize', 'CouponVoucher']),
    targetId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  }),
};
