import axios from 'axios';

import { formatQRCode } from '../utils/dataFormaters/qrcode';
import { getV3Url } from './variables';

export const getQRCode = (id, body) =>
  axios
    .post(
      `${getV3Url()}/project/${id}/qr_token`,
      body && {
        target_class: body.targetClass,
        target_id: body.targetId,
      },
    )
    .then((res) => formatQRCode(res.data));
