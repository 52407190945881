import { DEFAULT_THEME_COLOR } from '../../constants/smrsFrontstage';

export default {
  id: null,
  adJson: [],
  imgJson: {},
  levelJson: [],
  pointExpiredDate: '',
  preferenceJson: {
    fillBirthday: false,
    fillEmail: false,
    fillName: false,
    fillTel: false,
    needOtp: false,
  },
  priceToPointRate: null,
  userViewJson: {
    awardButtonLabel: null,
    contributionButtonLabel: null,
    makeContributionButtonColor: DEFAULT_THEME_COLOR,
    makeContributionButtonUrl: null,
    webLang: null,
    webTitle: null,
  },
  workingMode: '',
};
