import _ from 'lodash';

const variables = {
  endPoint: process.env.REACT_APP_END_POINT,
  v3: process.env.REACT_APP_API_ENDPOINT_V3,
};

export const getBaseUrl = () => {
  return _.get(variables, `endPoint`, '');
};

export const getV3Url = () => {
  return _.get(variables, 'v3', '');
};
