import './App.scss';
import 'react-toastify/dist/ReactToastify.css';

import React from 'react';
import { BrowserRouter as Router } from 'react-router-dom';
import { Slide, ToastContainer } from 'react-toastify';
import { SWRConfig } from 'swr';

import {
  LOYAL_T_KEY,
  PROJECT_ID_KEY,
  SWR_CONFIG,
} from './constants/smrsFrontstage';
import ProjectProvider from './context/Project/ProjectProvider';
import RouteGuard from './routes/RouteGuard';

function App() {
  const token = localStorage.getItem(LOYAL_T_KEY);
  const projectId = localStorage.getItem(PROJECT_ID_KEY);
  // eslint-disable-next-line
  console.info(process.env.REACT_APP_VERSION, `(${process.env.REACT_APP_ENV})`);

  return (
    <SWRConfig
      value={{
        ...SWR_CONFIG,
        onErrorRetry: (err, _key, _config, revalidate, { retryCount }) => {
          if (err.status === 404) {
            window.location = '404';
            return;
          }
          if (!token && !projectId) {
            window.location = '404';
            return;
          }
          // only retry 10 times
          if (retryCount >= 10) {
            return;
          }
          // retry after 1 sec
          setTimeout(() => revalidate({ retryCount: retryCount + 1 }), 1000);
        },
      }}
    >
      <Router>
        <ProjectProvider>
          <RouteGuard />
          <ToastContainer
            autoClose={3000}
            closeButton={false}
            transition={Slide}
            hideProgressBar={true}
          />
        </ProjectProvider>
      </Router>
    </SWRConfig>
  );
}

export default App;
