export const formatQRCode = (res) => {
  const { data } = res;
  return {
    qrTokens: {
      expireTime: data.qr_tokens.expire_time,
      expiredAt: data.qr_tokens.expired_at,
      token: data.qr_tokens.token,
    },
  };
};
