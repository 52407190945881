export const formatUserInfo = (data) => {
  const result = {
    awardAnnalCountJson: data.user.award_annal_count_json,
    birthday: data.user.birthday,
    costPoint: data.user.cost_point,
    email: data.user.email,
    fieldAnnalCountJson: data.user.field_annal_count_json,
    firstName: data.user.first_name,
    gender: data.user.gender,
    lastName: data.user.last_name,
    level: data.user.level,
    levelIndex: data.user.level_index,
    levelName: data.user.level_name,
    memberCardNum: data.user.member_card_num,
    name: data.user.name,
    policyAgreedAt: data.user.policy_agreed_at,
    profilePic: data.user.profile_pic,
    signupCompletedAt: data.user.signup_completed_at,
    tel: data.user.tel,
    telConfirmedAt: data.user.tel_confirmed_at,
    totalAwardPoint: data.user.total_award_point,
    // unconfirmedTel: data.user.unconfirmed_tel,
    validAwardPoint: data.user.valid_award_point,
  };
  if (!result.name) {
    result.name = `${result.firstName} ${result.lastName}`;
  }
  return result;
};

export const formatSignUpFormPayload = (data) => {
  return {
    ...(!!data.name && { name: data.name }),
    ...(!!data.birthday && { birthday: data.birthday }),
    ...(!!data.tel && { tel: data.tel }),
    ...(!!data.email && { email: data.email }),
    ...(!!data.agreeTerms && { policy_agree: data.agreeTerms }),
  };
};
