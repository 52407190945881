import PropTypes from 'prop-types';
import React from 'react';
import styled from 'styled-components';

const StyledWrapper = styled.div`
  border-radius: 50%;
  overflow: hidden;
  height: 4.7rem;
  width: 4.7rem;

  img {
    height: 100%;
    width: 100%;
  }
`;

export const Portrait = ({ img, name }) => {
  return (
    <StyledWrapper>
      <img src={img} alt={name} />
    </StyledWrapper>
  );
};

Portrait.propTypes = {
  img: PropTypes.string,
  name: PropTypes.string,
};
