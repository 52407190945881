import _ from 'lodash';
import React, { Suspense, useContext, useEffect, useState } from 'react';
import { Route, Switch, useHistory } from 'react-router-dom';
import { ThemeProvider } from 'styled-components';
import useSWR from 'swr';

import { fetcherV3 } from '../api/fetcher';
import LoadingFullScreen from '../components/LoadingFullScreen';
import { defaultTheme } from '../constants/initialState';
import {
  DEFAULT_THEME_COLOR,
  LOYAL_T_KEY,
  PROJECT_ID_KEY,
} from '../constants/smrsFrontstage';
import ConditionalWrapper from '../containers/ConditionalWrapper';
import CreateStyles from '../containers/GlobalStyle/CreateStyles';
import ProjectContext from '../context/Project/ProjectContext';
import { hexToRgb, textColor } from '../utils/color';
import { formatUserInfo } from '../utils/dataFormaters/user';
import config from './config';
import PrivateRoute from './PrivateRoute';

const WithoutAuth = _.map(config, (route) => !route.needAuth && route.path);

const RouteGuard = () => {
  const {
    location: { pathname },
  } = useHistory();
  const projectId = localStorage.getItem(PROJECT_ID_KEY);
  const token = localStorage.getItem(LOYAL_T_KEY);
  const [initCompleted, setInitCompleted] = useState(false);
  const { data: userData, error: userError } = useSWR(
    WithoutAuth.indexOf(pathname) !== -1 ? null : `/project/${projectId}/user`,
    (req) => fetcherV3(req, formatUserInfo),
  );
  const { project } = useContext(ProjectContext);
  const [mainColor, setMainColor] = useState(DEFAULT_THEME_COLOR);
  const rgb = hexToRgb(mainColor);
  const color = textColor(rgb.r, rgb.g, rgb.b);

  useEffect(() => {
    if (project) {
      setMainColor(
        _.get(
          project,
          'userViewJson.makeContributionButtonColor',
          DEFAULT_THEME_COLOR,
        ),
      );
    }
  }, [project]);

  useEffect(() => {
    // if (userData) {
    //   dispatchUser({ type: SET_USER, payload: userData });
    // }

    if (userError && token) {
      localStorage.removeItem(LOYAL_T_KEY);
    }
    setInitCompleted(true);
  }, [userData, userError]);

  if (!initCompleted) return null;

  return (
    <ThemeProvider
      theme={{
        ...defaultTheme,
        mainColor,
        rgb,
        color,
      }}
    >
      <CreateStyles />
      <Switch>
        {_.map(config, (route) => {
          return (
            <Route key={route.path} path={route.path}>
              <ConditionalWrapper
                condition={route.needAuth || route.needSignupCompleted}
                wrapper={(children) => (
                  <PrivateRoute
                    needAuth={route.needAuth}
                    needSignupCompleted={route.needSignupCompleted}
                  >
                    {children}
                  </PrivateRoute>
                )}
              >
                <route.layout>
                  <main>
                    <Suspense fallback={<LoadingFullScreen active />}>
                      <route.component />
                    </Suspense>
                  </main>
                </route.layout>
              </ConditionalWrapper>
            </Route>
          );
        })}
      </Switch>
    </ThemeProvider>
  );
};

export default RouteGuard;
