export const LOGIN = '/login';
export const LANDING_TOKEN = '/landing/:token';
export const LANDING = '/landing';

export const SIGN_UP = '/sign-up';
export const OTP_VERIFY = '/verify/otp';

export const MEMBER_INFO = '/member/info';
export const MEMBER_COUPON = '/member/coupon';

export const SETTING_PROFILE = '/setting/profile';

export const PAGE_NOT_FOUND = '/404';
export const ROOT = '/';

export const BACKEND_FB_LOGIN = '/user/login';

export const FIELD_PAGE = '/field';

export const HOME_PAGE = '/home';

export const PRIZE_PAGE = '/prize';

export const COUPON_PAGE = '/coupon';
export const COUPON_PAGE_USED_ONLINE = '/coupon/used/online';
export const COUPON_PAGE_USED_OFFLINE = '/coupon/used/offline';

export const POINT_HISTORY = '/history/point';
export const TRANSACTION_HISTORY = '/history/transaction';
