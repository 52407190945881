import PropTypes from 'prop-types';
import React from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

import { QRCodeModal } from '../QRCodeModal';

const StyledH3 = styled.h3`
  color: ${({ theme }) => theme.mainColor};
  text-align: center;
  font-size: 1rem;
  font-weight: normal;
  margin-bottom: 1.5rem;
`;

const StyledH4 = styled.h4`
  color: ${({ theme }) => theme.gray.dark};
  font-size: 0.875rem;
  margin-bottom: 0.5rem;
`;

const StyledInfo = styled.div`
  color: ${({ theme }) => theme.gray.medium};
  font-size: 0.875rem;
  text-align: justify;
  white-space: pre-wrap;
`;

/**
 * @param props
 * @param props.isOpen - boolean
 * @param props.title - string
 * @param props.info - string
 * @param props.onCancel - function
 * @returns JSX.Element
 */
export const MemberQRCode = ({ isOpen, onCancel, title, info }) => {
  const { t } = useTranslation();

  return (
    <QRCodeModal isOpen={isOpen} onCancel={onCancel}>
      <article>
        <StyledH3>{title}</StyledH3>
        <StyledH4>{t('common.instructions')}</StyledH4>
        <StyledInfo>{info}</StyledInfo>
      </article>
    </QRCodeModal>
  );
};

MemberQRCode.proptypes = {
  isOpen: PropTypes.boolean,
  onCancel: PropTypes.function,
  title: PropTypes.string,
  info: PropTypes.string,
};
