export const PHONE_NUMBER_LIMIT = 10;

export const DEFAULT_THEME_COLOR = '#383838';

export const PROJECT_ID_KEY = 'loyal_pj_id';
export const LOYAL_T_KEY = 'loyal_t';

export const DEFAULT_LOGO =
  'https://goskyai.com/wp-content/uploads/2021/01/logo-300x54.png';

export const SWR_CONFIG = {
  revalidateOnFocus: false,
  errorRetryInterval: 1000,
  // cache stale in 1 minute
  dedupingInterval: 60 * 1000,
};

export const ONLIN_AND_OFFLINE_FLOW = 'online_and_offline_flow';
export const OFFLINE_FLOW = 'offline_flow';
export const ONLINE_FLOW = 'online_flow';

export const USER_INFO_REFRESH_TIME = 3000;
