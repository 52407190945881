export const hexToRgb = (hex) => {
  let h = hex;
  // Reference: https://stackoverflow.com/a/5624139/8609168
  // Expand shorthand form (e.g. "03F") to full form (e.g. "0033FF")
  const shorthandRegex = /^#?([a-f\d])([a-f\d])([a-f\d])$/i;
  h = hex.replace(shorthandRegex, (m, r, g, b) => {
    return r + r + g + g + b + b;
  });

  const result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(h);
  return result
    ? {
        r: parseInt(result[1], 16),
        g: parseInt(result[2], 16),
        b: parseInt(result[3], 16),
      }
    : null;
};

export const textColor = (r, g, b) => {
  // Reference: https://stackoverflow.com/a/3943023/8609168
  if (r * 0.299 + g * 0.587 + b * 0.114 > 150) {
    return '#000000';
  }
  return '#ffffff';
};
