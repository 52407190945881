import { FIELD_PAGE, HOME_PAGE, PRIZE_PAGE, COUPON_PAGE } from './routePaths';
import { ONLINE_FLOW } from '../constants/smrsFrontstage';

export const NAVBAR_MENU = (mode) => {
  if (mode === ONLINE_FLOW) {
    return [
      {
        to: FIELD_PAGE,
        name: 'field',
        active: 'point-circle-fill',
        inactive: 'point-circle',
      },
      {
        to: PRIZE_PAGE,
        name: 'prize',
        active: 'exchange',
        inactive: 'exchange',
      },
    ];
  }

  return [
    {
      to: HOME_PAGE,
      name: 'home',
      active: 'home-fill',
      inactive: 'home',
    },
    {
      to: FIELD_PAGE,
      name: 'field',
      active: 'point-circle-fill',
      inactive: 'point-circle',
    },
    {
      to: PRIZE_PAGE,
      name: 'prize',
      active: 'exchange',
      inactive: 'exchange',
    },
    {
      to: COUPON_PAGE,
      name: 'coupon',
      active: 'ticket-fill',
      inactive: 'ticket',
    },
  ];
};
