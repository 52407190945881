import './index.scss';

// import classNames from 'classnames';
import PropTypes from 'prop-types';
import React, { useCallback, useEffect, useState } from 'react';
import styled, { keyframes } from 'styled-components';

import Portal from '../../containers/Portal';
import { Scrollbar, Flex } from '../../Mixin';
import Fade from '../Fade';

const pulseAnim = keyframes`
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.02);
  }
  100% {
    transform: scale(1);
  }
`;

const StyledOverlay = styled.div`
  ${Scrollbar}
  height: 100%;
  width: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  position: fixed;
  top: 0;
  left: 0;
`;

const ModalCenter = styled.div`
  ${Flex.center}
  min-height: calc(100% - 1.75rem * 2);
  max-width: 21rem;
  width: calc(100vw - 5rem);
  margin: 1.75rem auto;
`;

const ModalContent = styled.div`
  background-color: ${({ theme }) => theme.white};
  border: 0;
  border-radius: 0.625rem;
  position: relative;
  padding: 3.625rem 1.875rem 2.875rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;

  ${({ isPulse }) => {
    if (isPulse) {
      return `
        animation: ${pulseAnim} 0.1s;
      `;
    }
    return null;
  }}
`;

const CloseButton = styled.i`
  position: absolute;
  top: 0.625rem;
  right: 0.625rem;
  color: ${({ theme }) => theme.gray.light};

  &.close-button {
    font-size: 1.75rem;
  }
`;

const Modal = ({
  id = 'modal',
  children,
  persistent = false,
  isOpen = false,
  style = {},
  onRequestClose = () => {},
}) => {
  const [isPulse, setIsPulse] = useState(false);

  const onCloseModal = useCallback(() => {
    if (persistent) {
      setIsPulse(true);
    } else {
      onRequestClose();
    }
  }, [persistent, setIsPulse, onRequestClose]);

  const onPulseAnimationEnd = () => {
    setIsPulse(false);
  };

  useEffect(() => {
    const handleEscKeyDown = (e) => {
      // keyCode === 27 is Escape.
      if (e.keyCode === 27) onCloseModal();
    };
    if (isOpen) document.addEventListener('keydown', handleEscKeyDown);
    return () => {
      document.removeEventListener('keydown', handleEscKeyDown);
    };
  }, [isOpen, onCloseModal]);

  return (
    <Portal id={id}>
      <Fade show={isOpen}>
        <StyledOverlay onClick={onCloseModal}>
          <ModalCenter>
            <ModalContent
              isPulse={isPulse}
              style={style}
              onClick={(e) => e.stopPropagation()}
              onAnimationEnd={onPulseAnimationEnd}
            >
              <CloseButton
                className="gsif gsif-times-circle close-button"
                onClick={onRequestClose}
              />
              {children}
            </ModalContent>
          </ModalCenter>
        </StyledOverlay>
      </Fade>
    </Portal>
  );
};

export default Modal;

Modal.propTypes = {
  id: PropTypes.string,
  children: PropTypes.node,
  persistent: PropTypes.bool,
  isOpen: PropTypes.bool,
  style: PropTypes.object,
  onRequestClose: PropTypes.func,
};
