import React, { useState, useEffect } from 'react';
import './index.scss';
import Loading from '../Loading';

function LoadingFullScreen({ active = false }) {
  const [getActive, setActive] = useState(active);
  useEffect(() => setActive(active), [active]);
  const loadingStyle = {
    height: window.innerHeight,
  };

  return (
    <div
      className={`loading-full-screen ${getActive ? 'fade-in' : 'fade-out'}`}
      style={loadingStyle}
    >
      <Loading />
    </div>
  );
}

export default LoadingFullScreen;
