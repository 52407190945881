import axios from 'axios';
import { getBaseUrl } from './variables';

axios.defaults.baseURL = getBaseUrl();
axios.defaults.headers.post['Content-Type'] = 'application/json;charset=utf-8';

axios.interceptors.request.use(
  (config) => {
    const reqConfig = config;
    const token = localStorage.getItem('loyal_t');
    if (token) reqConfig.headers['gosky-api-key'] = token;
    return reqConfig;
  },
  (error) => {
    // eslint-disable-next-line
    return Promise.reject(error);
  },
);

axios.interceptors.response.use(
  (response) => response,
  (error) => {
    if (axios.isCancel(error)) {
      return Promise.resolve();
    }
    return Promise.reject(error);
  },
);
