import { createGlobalStyle } from 'styled-components';

const CreateStyles = createGlobalStyle`
html, body {
  height: 100%;
  width: 100%;
}

#root {
  min-height: 100%;
  display: flex;
  flex-direction: column;
  align-items: stretch;

  main {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    margin-bottom: 4.25rem;
    position: relative;
  }
}

.theme-button {
  background-color: ${({ theme }) => theme.mainColor};
  color: ${({ theme }) => theme.color};
  border: none;
}
.theme-button-outline {
  background-color: white;
  color: ${({ theme }) => theme.mainColor};
  border: solid 1px ${({ theme }) => theme.mainColor};
}
.theme-color {
  color: ${({ theme }) => theme.mainColor};
}
.theme-background-color {
  background-color: ${({ theme }) => theme.mainColor};
}
.theme-card {
  background-color: ${({ theme }) => theme.mainColor};
  color: ${({ theme }) => theme.color};
}
.theme-checkbox {
  color: ${({ theme }) => theme.mainColor};
}
.theme-checkbox .checkmark-wrapper {
  background-color: rgba(${({ theme }) => theme.rgb.r}, ${({ theme }) =>
  theme.rgb.g}, ${({ theme }) => theme.rgb.b}, 0.15);
}
`;

export default CreateStyles;
